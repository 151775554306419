import React, { useState, useEffect } from 'react';
import Search from '../component/search';
import {  useParams } from 'react-router-dom';
import TableTd from './table-td';
import MyModal from './my_modal';
import MyNewModal from './my_new_modal';
import GetRecords from './get-records';
import axios from 'axios';
import { Button } from 'react-bootstrap';
//import clone_record from './clone_record';



function TableList(props) {


  var url = props.host+'/api/new_record.php?table='+table;

  var table;
  const params = useParams();
  if(params.table===undefined){
    table = ''
  }else{
    table = params.table;
  }
  
  const [state, setState] = useState({
    product: [],
    columns: []
  });
  const [mynew, setMyNew] = useState(1);
  const [show, setShow] = useState(0);
  const [show_new, setShowNew] = useState(0);
    
  const [just_closed, setJustClosed] = useState(1);
  const [just_closed_new, setJustClosedNew] = useState(1);

  
  const [columns, setColumns] = useState([]);

  const [product, setProduct] = useState([]);

  const [records, setRecords] = useState([]);
    const [td_editing, setEdited] = useState('');
    const [td_editing_id, setEditedId] = useState('');
    //var td_editing ='';
    //var td_editing_id ='';

    const [node, setNode] = useState('');

    const [node_input, setInputNode] = useState('');
    const [edit_flag, setEditFlag] = useState(false);
    const [search, setSearch] = useState('');
    const [updatekey, setKey] = useState(0)
    const [order_by, setOrder] = useState('id');
   const [myRef, setMyRef] = useState('');
   const [message, setMessage] = useState('');
//   const [edit_record_id, setEditRecordId] = useState('new');
   var edit_record_id = 'new';

    //var myRef='';




    const fetch_record = async (id)=>{
      //alert(id);
      url = props.host+'/api/record.php?table='+table+'&id='+id;
    
        const fetchData = async() => {
              await axios.get(url)
              .then(response => {
                //console.log(response.data)
                if(response.data.error=='' ){
                //console.log(response.data);
                console.log('CONSEGUI DATOS... '+url);
          
                //console.log(response.data.table);
                setColumns(response.data.columns);
                const product1=response.data.table;
                setProduct(product1);
/*                 setState(prevState => {
                  return {
                    ...prevState,
                    product: product1
                  };
                });
 */
              setShow(true);
               //console.log(state);
                return product1;
                
          
              }else{
                  console.error(response.data.error);
                  alert('errorrrr'+id+'>'+response.data.error);
          
                }
              })
              .catch(error => {
                console.error(error);
                alert('erroreee'+error);
              });
          
            }
        const product2 = await fetchData();
        return () => {
          // this now gets called when the component unmounts
          //console.log('return render',product);

        };
    
    
    }
    
    const fetch_new_record = async ()=>{
      //alert(id);
      url = props.host+'/api/new_record.php?table='+table;
    
        const fetchData = async() => {
              await axios.get(url)
              .then(response => {
                //console.log(response.data)
                if(response.data.error=='' ){
                //console.log(response.data);
                console.log('CONSEGUI DATOS... '+url);
          
                //console.log(response.data.table);
                setColumns(response.data.columns);
                const product1=response.data.table;
                setProduct(product1);
/*                 setState(prevState => {
                  return {
                    ...prevState,
                    product: product1
                  };
                });
 */
              setShowNew(true);
               //console.log(state);
                return product1;
                
          
              }else{
                  console.error(response.data.error);
                  alert('errorrrr'+'>'+response.data.error);
          
                }
              })
              .catch(error => {
                console.error(error);
                alert('erroreee'+error);
              });
          
            }
        const product2 = await fetchData();
        return () => {
          // this now gets called when the component unmounts
          //console.log('return render',product);

        };
    
    
    }



    const togle = (e)=>{
      if(edit_flag){
        setEditFlag(false);
        setMessage('You are in Database mode')
      }else{
        setEditFlag(true);
        setMessage('You are in Spreadsheet mode')
      }
    }
  
      const delete_record = (did)=>{
       const durl = props.host+'/api/delete_record.php?table=';
        axios.get(durl+table+'&id='+did)
          .then(response => {
            //console.log(response.data)
            if(response.data.error===''){
              //console.log(response.data);
              const new_id=response.data.id;
              setMessage('deleted record id:'+did);
              setKey(updatekey+1);        
            }else{
              console.error(response.data.error);
              alert(response.data.error);    
            }
          })
          .catch(error => {
            console.error(error);
            alert(error);
          });           
    }
   
    const clone_record = (did)=>{
      const durl = props.host+'/api/clone_record.php?table=';
      axios.get(durl+table+'&id='+did)
      .then(response => {
        //console.log(response.data)
        if(response.data.error===''){
          //console.log(response.data);
          const new_id=response.data.id;
          setMessage('cloned with id:'+new_id);
          setKey(updatekey+1);        
        }else{
          console.error(response.data.error);
          alert(response.data.error);    
        }
      })
      .catch(error => {
        console.error(error);
        alert(error);
      });           

    }

  
    

    useEffect(() => {
      if (product != null) {

        if( show_new==false && just_closed_new==0){
          setShowNew(true);
        }else{
          setJustClosedNew(0);          
        }


        if( show==false && just_closed==0){
          setShow(true);
        }else{
          setJustClosed(0);          
        }
      
      }
    }, []);


  

  return (
    <div>
      <Search  setSearch={setSearch} updatekey={updatekey} setKey={setKey} td_editing_id={td_editing_id}/>
      <GetRecords key={updatekey} table={table} setRecords={setRecords} setColumns={setColumns} search={search}
      order_by={order_by} setOrder={setOrder} host={props.host}/>

      {edit_flag == false ? <h2 title='add, look up, change, and remove information' >DATABASE MODE</h2> : <h2 >SPREADSHEET MODE</h2> }
      
    

                              

      <table key={1} border='1'>
      <thead  >
      <tr key={9999}>
          <th>ACTION:        

            {edit_flag == false ? 
            <span  >
                <Button  edit_record_id={'new'} host={props.host} table={table} 
                setKey={setKey} onClick={() => fetch_new_record() }
                updatekey={updatekey}  >&#10133; </Button>

             </span>
           
            : 
            <span >

            </span> 
            }
            {edit_flag == false ? 
              <span  >
                <Button className={td_editing_id==''?'button_enabled':'button_disabled'}
                           href='#' title='Database - click to SpreadSheet Mode' onClick={togle}> &#9638; </Button>             
               </span>
             
              : 
              <span >
                <Button className={td_editing_id==''?'button_enabled':'button_disabled'}
                href='#' title='SpreadSheet - click to Database Mode' onClick={togle}>  &#128199; </Button>
  
              </span> 
            }
            <span className='last_op'><br/> {message}</span>
  

          </th>
          {columns.map((column,i) => (
            <th>
              {column.f_show} 
              <span className={td_editing_id==''?'button_enabled':'button_disabled'} onClick={() => {setOrder(column.f_name); setKey(updatekey+1);} } >&#8595;</span>
              <span className={td_editing_id==''?'button_enabled':'button_disabled'} onClick={() => {setOrder(column.f_name+' desc'); setKey(updatekey+1);} } >&#8593;</span>
            </th>
          ))
        }
        </tr>
      </thead>
        <tbody  >
        {records.map((record,i) => (
          <tr key={i}>
            <td>
            {edit_flag == false ? 
            <Button  edit_record_id={record.id} host={props.host} table={table} 
             setKey={setKey} onClick={() => fetch_record(record.id) } 
             updatekey={updatekey}  >&#128393;</Button>

            :<span></span>
            }
              | <Button className={td_editing_id==''?'button_enabled':'button_disabled'} title='Duplicate record'  onClick={() => clone_record(record.id) }  > &#128461;</Button> |  
              <Button  className={td_editing_id==''?'button_enabled':'button_disabled'} title='Delete record'     onClick={() => delete_record(record.id) }    > &#128465;</Button> 
              
              </td>
             {
              Object.entries(record).map(([key2, value]) => (
                <TableTd record={record} table={table} key2={key2} value={value} edit_flag={edit_flag}  host={props.host} setMessage={setMessage}
                 td_editing={td_editing}  td_editing_id={td_editing_id}  myRef={myRef} setMyRef={setMyRef} setEditedId={setEditedId} setEdited={setEdited}
                 node_input={node_input}  setInputNode={setInputNode} node={node} setNode={setNode}  />
              ))              
             }

            </tr>
        ))}
      </tbody>
      </table>
      <MyModal  show={show} setShow={setShow} product={product} edit_record_id={product.id} host={props.host} table={table}
                just_closed={just_closed} setJustClosed={setJustClosed} setKey={setKey} updatekey={updatekey}  />
                
      <MyNewModal  show_new={show_new} setShowNew={setShowNew} product={product}  host={props.host} table={table}
                just_closed_new={just_closed_new} setJustClosedNew={setJustClosedNew} setKey={setKey} updatekey={updatekey}  />


    </div>
    
  );
}

export default TableList;
// AGREGAR CURSOR DE EXCEL CON MAS cursor: cell;