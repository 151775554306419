import React, { useState, useEffect } from 'react';
import { Routes,Route } from 'react-router-dom';
import axios from 'axios';

import TableList from './pages/table-list';

import Home from './pages/home';

import NoPage from './pages/no-page';
import "./App.css";

function App() {

  const [ip, setIP] = useState([]);

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    //console.log(res.data);
    setIP(res.data.ip);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);
  //const host='http://localhost/sogamx/';
  const host='https://sogaamx.reddeconfeccionistas.com/';

  return (

    <div>
      <p>
      <a href='/home'>| Home |</a> myip: {ip}
      </p>
        <Routes>
          <Route index element={<Home />}/>
          <Route path='/home' element={<Home />}/>
          <Route path='/table/:table' element={<TableList host={host}/>}/>
          <Route path='*' element={<NoPage />}/>
        </Routes>
    </div>
    
  );
}

export default App;
/**
 * hacer modal la edicion //ya
 * falta poner cursor de excel con focus y poder navegar y comenzar a editar con enter
 * validaciones en forms
 */
