import React, {Component } from 'react';
class Search extends Component{
    render(){
        const search = (e)=>{
            console.log(e);
            const search_value = document.getElementById("search_input").value
            //alert('search'+search_value);
            this.props.setSearch(search_value);
            this.props.setKey(this.props.updatekey+1);
        }
    
        return(
            <p>Search
                <input class={this.props.td_editing_id==''?'button_enabled':'button_disabled'} id='search_input'
                onKeyDown={(e) => { 
                if (e.key === "Enter") {search(e)} 
                }}  />
            </p>
        )
    }
}

export default Search;