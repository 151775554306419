import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyMewModal(props) {
  console.log('TD',props); //{}
  if(props.product.length === 0 ){
    return null;
  }

    var show=props.show_new;


  const handleCloseNew = () => {
    props.setShowNew(false);
    props.setJustClosedNew(1);
  }
  console.log('edit_record_id',props.edit_record_id); //{}


  var url = props.host+'/api/new_record.php?table='+props.table;

  return (
    <>    

      <Modal
        show={show}
        onHide={!show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
        <form onSubmit={handleSubmitNew}>
          {
            Object.entries(props.product).map(([key2, value]) => (
              <div>
                  <label for={key2}>{key2}</label>
                  <input type='text' key={key2} name={key2} label={key2} defaultValue={value} />
              </div>                
            ))              
          }
        
        <button>Save</button>

        </form>
        
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {handleCloseNew() }}>
            
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  
  async function handleSubmitNew(e){
    console.log('en submit');
    e.preventDefault();
    var formo = e.target
    var values = '';
    var comma = '';
    Array.from(formo).forEach((input) => {
        if(input.type=='text'){
            values += comma+input.name+"='"+input.value+"'";
            comma = ',';    
        }
    });
    var sql ="INSERT INTO "+props.table+" SET "+values;  
    console.log('en sql'+sql);

      url = props.host+'/api/update_field.php?table=';
      await axios.get(url+props.table+'&sql='+sql)
        .then(response => {
          if(response.data.error===''){
            console.log('all correct');
            props.setKey(props.updatekey+1);
            handleCloseNew();
             
          }else{
            console.error(response.data.error);
            alert('erroooor'+response.data.error+sql);
          }
        })
        .catch(error => {
          console.error(error);
          alert('erroooorrrr'+props.edit_record_id+'>'+sql+'>'+error);
        });    
  }

}

export default MyMewModal;