import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyModal(props) {
  console.log('TD',props); //{}
//  console.log('MyModal'+props.edit_record_id,props.updatekey);
  //const [show, setShow] = useState(true);
//var show=props.show;
//useState(props.show);
var show=props.show;

//var setShow=props.setShow;

  const handleClose = () => {
    props.setShow(false);
    props.setJustClosed(1);
  }
  if(props.product.length === 0 ){
    return null;
    }
    if(props.product==[]){
      return null;
    }

      

    var url = '';


  //alert(url);


  

  //console.log(product); //handleClose
  //console.log('render');

  return (
    <>    

      <Modal
        show={show}
        onHide={!show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
        <form onSubmit={handleSubmit}>
          {
            Object.entries(props.product).map(([key2, value]) => (
              <div>
                  <label for={key2}>{key2}</label>
                  <input type='text' key={key2} name={key2} label={key2} defaultValue={value} />
              </div>                
            ))              
          }
        
        <button>Save</button>

        </form>
        
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {handleClose() }}>
            
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  
  async function handleSubmit(e){
    e.preventDefault();
    //console.log(e.target);
    var formo = e.target
    var values = '';
    var comma = '';
    Array.from(formo).forEach((input) => {
        //console.log(input.type);
        if(input.type=='text'){
            values += comma+input.name+"='"+input.value+"'";
            comma = ',';    
        }
      });
      const sql = "UPDATE "+props.table+" SET "+values+ " WHERE id = '"+ props.edit_record_id +"'";  

      console.log('upd/ins:'+props.new+'>'+sql);
      url = props.host+'/api/update_field.php?table=';
      await axios.get(url+props.table+'&sql='+sql)
        .then(response => {
          //console.log(response.data)
          if(response.data.error===''){
            //alert('updated');
            props.setKey(props.updatekey+1);
            handleClose();
            //alert()
             
            //window.location = redirect;            
          }else{
            console.error(response.data.error);
            alert('erroooor'+response.data.error+sql);
          }
        })
        .catch(error => {
          console.error(error);
          alert('erroooorrrr'+props.edit_record_id+'>'+sql+'>'+error);
        });    
  }

}

export default MyModal;