import { useEffect } from 'react';
import axios from 'axios';


function GetRecords(props) {
  //console.log(props);
    //const url = 'https://sogaamx.reddeconfeccionistas.com/api/table.php?table=';
    const url = props.host+'/api/table.php?table='+props.table+"&search="+props.search+"&order="+props.order_by;
    //console.log(url);
    useEffect(() => {

    axios.get(url)
      .then(response => {
        //console.log(response.data)
        if(response.data.error===''){
        console.log('GET RECORDS')
        props.setRecords(response.data.table);
          props.setColumns(response.data.columns);
        }else{
          //console.error(response.data.error);
          alert(response.data.error);

        } 
      })
      .catch(error => {
        //console.error(error);
        alert(error);
      });
  }, []);

}

export default GetRecords;