import React, {Component } from 'react';
class NoPage extends Component{

    render(){
        return(
    <div>
        <h1>404 Error - No Page Found</h1>
    </div>
        )
    }
  
}

export default NoPage;