import React, {Component } from 'react';
class NoPage extends Component{

    render(){
        return(
    <div>
        <h1>This is home</h1>
        <ul><h3>Tables</h3> 
            <li><a href='/table/colores'>colores</a></li>
            <li><a href='/table/tipo_prendas'>tipo_prendas  </a></li>
            <li><a href='/table/escalas'>Tallas  </a></li>
            <li><a href='/table/generos'>Generos  </a></li>
            <li><a href='/table/categorias_tela'>categorias_tela  </a></li>
            <li><a href='/table/marcas'>marcas  </a></li>
            <li><a href='/table/modelos'>modelos  </a></li>
            <li><a href='/table/disenos_impresion'>dibujos  </a></li>
            <li><a href='/table/procesos_impresion'>Procesos  </a></li>
            <li><a href='/table/maquileros'>Maquileros  </a></li>

        </ul>
    </div>
        )
    }
  
}

export default NoPage;