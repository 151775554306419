import axios from 'axios';


function TableTd(props) {
  //console.log(props.record.id);
  var old_value='';
  const td_editing_id=props.td_editing_id;
//  console.log('td_editing_id'+td_editing_id);

  const handleKey = e => {
    console.log('e',e);
    if(e.keyCode == 13 ){
      console.log('target',e.target.value);
      console.log('parentElement',e.target.parentElement.id);

      update_input(e.target.value,e.target.parentElement.id,e.target.parentElement);
    }
    if(e.keyCode == 27 ){
      console.log('target',e.target.value);
      console.log('parentElement',e.target.parentElement.id);

      not_update_input(e.target.parentElement);
    }


  }

  const clickme = (e) => {
    console.log(e.target.tagName);
    if(props.edit_flag==false){
      props.setMessage('not in spreadsheet mode');
      return null;
    }
    if(e.target.tagName=='INPUT'){
      return null;
    }
    e.preventDefault();

    if(props.myRef == '' && td_editing_id==''){
      var tdRef=e.target;

        var node = document.createElement("input");  // Create a new list node
        var textcontent = tdRef.textContent;
        old_value=textcontent;
        //alert(props.old_value);
        var textnode = document.createTextNode(textcontent);  // Create a text node
        node.appendChild(textnode); 
        node.setAttribute('value', textcontent);
        //node.setAttribute('id', 'edit_input');
        //node.addEventListener('click', sayNothing)
        node.addEventListener('keydown', handleKey)
        tdRef.innerHTML='';
        tdRef.appendChild(node);
        props.setInputNode(node);      
        //editRef.current++;

        tdRef.style.backgroundColor = 'yellow';



        //alert('setEditedId'+tdRef.id);
        props.setEditedId(tdRef.id);

        props.setEdited(tdRef);
        props.setMyRef(tdRef);


        props.setNode(tdRef);      

        node.focus();
      }else if(td_editing_id != ''){
        console.log('2keep editing');
        update_input(props.node_input.value,props.td_editing_id,props.td_editing)
        //alert(field_updated);

      }else{
         console.log('3keep editing');  
      }
    
}
function not_update_input (myRef1){
  console.log(old_value)
  var row_node=myRef1;
  var textcontent =  old_value; //props.old_value;
  var textnode = document.createTextNode(textcontent);  // Create a text node
  row_node.innerHTML = '';
  row_node.appendChild(textnode); 
  row_node.style.backgroundColor = 'white';
  props.setEditedId('');
  props.setEdited('');
  props.setMyRef(''); 

}
//async - await axios
function update_input (input_value,td_editing_id1,myRef1){
    

  var row_node=myRef1;
  var textcontent =  input_value;
  console.log( 'td_editing_id',td_editing_id1);

  const update_args=td_editing_id1.split("__");
  const table = update_args[0];
  const sql = "UPDATE "+update_args[0]+" SET "+update_args[2]+" = '"+textcontent+"' WHERE id = '"+ update_args[1]+"'";  
  console.log(sql);
  const url = props.host+'/api/update_field.php?table=';
  axios.get(url+table+'&sql='+sql)
    .then(response => {
      //console.log(response.data)
      if(response.data.error===''){
/*          
        var textnode = document.createTextNode(textcontent);  // Create a text node
        row_node.innerHTML = '';
        row_node.appendChild(textnode); 
        row_node.style.backgroundColor = 'white';
        props.setEditedId('');
        props.setEdited('');
 */
        props.setMessage('updated!');
        console.log('all correct'+textcontent);

// return input_value; 
        //td_editing_id='';

    
      }else{
        console.error(response.data.error);
        //alert();
        props.setMessage(response.data.error);

      }
    })
    .catch(error => {
      console.error(error);
      props.setMessage('Connection Error');

      //alert(error);
    });
    props.setMessage('updating...');

    var textnode = document.createTextNode(textcontent);  // Create a text node
    row_node.innerHTML = '';
    row_node.appendChild(textnode); 
    row_node.style.backgroundColor = 'white';
    props.setEditedId('');
    props.setEdited('');
    props.setMyRef(''); 

} 

  return (
    <td className ={props.edit_flag?'cell_xl':'cell_navigate'} key={`${props.table}__${props.record.id}__${props.key2}`} id={`${props.table}__${props.record.id}__${props.key2}`} title={`id=${props.record.id} ${props.key2}=${props.value}`} onClick={clickme} >
    {props.value}
    </td>   
  );
}

export default TableTd;